import React, { useEffect, useState } from 'react';
import { request } from '../../../../utils/http';
import { AnchorButton, Intent, ProgressBar } from '@blueprintjs/core';
export default function ImportProgress(props) {
    const [job, setJob] = useState(null);
    let interval = 0;
    const refreshJob = (jobStatusUrl) => {
        request('GET', jobStatusUrl).then((res) => {
            setJob(res.data);
            if (res.data.finished) {
                interval && clearInterval(interval);
            }
        });
    };
    useEffect(() => {
        setTimeout(() => {
            refreshJob(props.statusUrl);
            interval = window.setInterval(() => refreshJob(props.statusUrl), 3000);
        }, 1000);
    }, []);
    if (job && job.finished) {
        return (React.createElement(React.Fragment, null,
            React.createElement("h5", { className: "bp4-heading" }, props.labels.finishedTitle),
            React.createElement("p", { className: "bp4-text-muted" }, props.labels.finishedDesc),
            React.createElement(ProgressBar, { intent: Intent.SUCCESS, value: 1.0, animate: false, stripes: false }),
            React.createElement("br", null),
            React.createElement(AnchorButton, { href: props.contactsUrl, text: props.labels.finishedBack, outlined: true, intent: 'slate' })));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("h5", { className: "bp4-heading" }, props.labels.processingTitle),
        React.createElement("p", { className: "bp4-text-muted" }, props.labels.processingDesc + (job ? ' (' + job.step + ')' : '')),
        React.createElement(ProgressBar, { intent: Intent.PRIMARY, value: undefined })));
}
